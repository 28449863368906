body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: LogoFont;
  src: url(/static/media/HelveticaNeueLTStd-Bd.9ac7e2ff.otf);
}
@font-face {
  font-family: ParagraphFont;
  src: url(/static/media/HelveticaNeueLTStd-Lt.06137036.otf);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden !important;
  padding-bottom: 20vh;
}
.logotext {
  position: relative;
}
.smaller-text {
  margin-top: -1em;
  box-sizing: border-box;
  font-size: 1.8vh;
}
.nav-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 70vw;
  height: 10vh;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.nav-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: -20vw;
  width: 150vw;
  height: 10vh;
  border-bottom: 0.25vh solid black;
}
.nav-logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.nav-logo-text {
  font-size: 3.3vh;
  font-family: LogoFont;
}
.nav-text {
  font-size: 2vh;
  font-weight: 400;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.underline {
  color: black;
  text-decoration: none;
  box-sizing: border-box;
  border-bottom: 0.25vh solid black;
}

.home-container {
  height: 70vh;
  width: 70vw;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.div1 {
  grid-area: 1 / 1 / 3 / 6;
}
.div2 {
  grid-area: 3 / 3 / 5 / 6;
  display: flex;
  justify-content: right;
  font-size: 10vh;
}
.div3 {
  grid-area: 5 / 3 / 6 / 6;
  margin-top: -7vh;
  display: flex;
  flex-direction: row-reverse;
  justify-content: right;
  position: relative;
}
.div4 {
  grid-area: 3 / 1 / 6 / 3;
}
.something {
  letter-spacing: -0.05em;
  font-size: clamp(10vh, 17vw, 24.5vh);
  line-height: 0.8;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  margin-top: 0;
}
.token {
  letter-spacing: -0.05em;
  font-size: clamp(10vh, 17vw, 24.5vh);
  line-height: 0.8;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  margin-top: -0.25em;
}
.top-text {
  margin-top: 1vh;
}
.top {
  white-space: nowrap;
  font-size: clamp(1vh, 3vw, 5vh);
  font-weight: 500;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.bottom-text {
  margin-top: 5vh;
}
.bottom {
  white-space: nowrap;
  font-size: clamp(1vh, 1.5vw, 2.1vh);
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.div3 > p {
  margin-top: 2vh;
}
.connectButton {
  position: relative;
  background-color: #8e8e8e92;
  border-radius: 2em;
  height: 8vh;
  width: 20vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 2.5vh;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.5s, -webkit-transform 0.1s cubic-bezier(0.5, 0, 0.5, 1);
  transition: transform 0.1s cubic-bezier(0.5, 0, 0.5, 1), box-shadow 0.5s;
  transition: transform 0.1s cubic-bezier(0.5, 0, 0.5, 1), box-shadow 0.5s, -webkit-transform 0.1s cubic-bezier(0.5, 0, 0.5, 1);
  margin-right: 1em;
}
/* .connectButton:hover {
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.616);
} */
.connectButton:active {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  transition-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
}
.connectButton > p {
  position: absolute;
  left: 15%;
}
.carret {
  height: 35%;
  right: 10%;
  position: absolute;
}
.clickSelector {
  height: 8vh;
  width: 30%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 3vh;
  font-weight: 400;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.openseaLogo {
  height: 50%;
  margin-right: 0.2em;
}
.underline-on-hover {
  margin-left: 0;
  display: inline;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  color: black;
}
.underline-on-hover:after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  width: 0;
  bottom: -1px;
  background: #000;
  height: 0.25vh;
  transition-property: width;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.underline-on-hover:hover:after,
.underline-on-hover:focus:after,
.underline-on-hover:active:after {
  left: auto;
  right: 0;
  width: 100%;
}
.decrement:active,
.increment:active {
  -webkit-transform: scale(0.85);
          transform: scale(0.85);
  transition-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
}
.stealthText {
  width: 30%;
  padding-right: 1em;
  font-size: clamp(1vh, 1.5vw, 2vh);
  font-weight: 500;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.erroralert {
  font-size: 2vh;
  font-weight: 300;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: absolute;
  bottom: 40%;
  color: red;
}
.ticker {
  width: 100%;
}
.marquee {
  min-width: 10% !important;
}
.mobile-bottom-text {
  display: none;
}
.large-scroll-text {
  position: absolute;
  font-size: 40vh;
  font-weight: 700;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

  bottom: 0;
  margin-bottom: -21vh;
  overflow: hidden;
  color: #0078e7;
  margin-right: 0;
  margin-left: 0;
}
.scroll {
  box-sizing: border-box;
  padding: 0 !important;
  margin: 0 !important;
}
.web3modal-provider-wrapper {
  box-sizing: border-box;
  background-color: #0078e7 !important;
}
.web3modal-provider-name {
  color: black !important;
  font-weight: 500 !important;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}
.web3modal-provider-description {
  color: black !important;
  font-weight: 400 !important;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .App {
    padding-bottom: 20vh;
  }
  .nav-container {
    align-items: center;
    justify-content: center;
    width: 100vw;
    padding-top: 2%;
  }
  .nav-logo-text {
    font-size: 3.5vh;
    font-weight: 800 !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    letter-spacing: -0.05em;
  }
  .nav-logo {
    line-break: none;
  }
  .nav-text {
    display: none;
  }
  .something,
  .token {
    width: 100vw;
    line-height: 1;
    font-size: 17vw;
  }
  .something {
    text-align: left;
  }
  .token {
    margin-top: 0;
    text-align: right;
  }
  .home-container {
    display: flex;
    flex-direction: column;
    width: 90vw;
    align-items: center;
  }
  .large-scroll-text {
    font-size: 18vh;
    font-weight: 500;
    margin-bottom: -10vh;
    letter-spacing: -0.05em;
  }
  .bottom {
    white-space: normal;
    letter-spacing: -0.05em;
    font-weight: 400;
    font-size: 3vw;
    line-height: 1.5em;
  }
  .div1 {
  }
  .div2 {
  }

  .clickSelector,
  .stealthText {
    width: 100%;
    height: 10vh;
    justify-content: center;
  }
  .connectButton {
    margin-right: 0;
  }

  .connectButton:hover {
    box-shadow: none;
  }
  .div3 > p {
    white-space: wrap;

    margin-top: 0;
  }
  .erroralert {
    display: none;
  }
  .bottom-text {
    margin-top: 2vh;
  }
  .div4 {
    display: flex;
    width: 100%;
    height: 35vh;
    justify-content: center;
    align-items: center;
    margin-top: -7vh;
  }
  .top {
    font-size: 4vw;
  }

  .div3 {
    margin-top: -3vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .mobile-bottom-text {
    display: flex;
    text-align: center;
    font-size: 4vw;
    font-weight: 500;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-top: 2vh;
  }
}
@media screen and (max-width: 768px) {
  .nav-container {
    align-items: center;
    justify-content: center;
    width: 100vw;
    padding-top: 2%;
  }
  .nav-logo-text {
    font-size: 3.5vh;
    font-weight: 800 !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    letter-spacing: -0.05em;
  }
  .nav-logo {
    line-break: none;
  }
  .nav-text {
    display: none;
  }
  .something,
  .token {
    width: 100vw;
    line-height: 1;
    font-size: 21vw;
  }
  .something {
    text-align: left;
  }
  .token {
    margin-top: -0.1em;
    text-align: right;
  }
  .home-container {
    display: flex;
    flex-direction: column;
    width: 90vw;
    align-items: center;
  }
  .large-scroll-text {
    /* display: none; */
    font-size: 18vh;
    font-weight: 500;
    margin-bottom: -10vh;
    letter-spacing: -0.05em;
  }
  .bottom {
    white-space: normal;
    letter-spacing: -0.05em;
    font-weight: 400;
    font-size: 4vw;
    line-height: 1.5em;
  }
  .div1 {
    height: 12vh;
  }
  .div2 {
  }

  .div3 {
    margin-bottom: 10vh;
  }

  .clickSelector,
  .stealthText {
    width: 100%;
    height: 10vh;
    justify-content: center;
  }
  .connectButton {
    margin-right: 0;
  }
  .connectButton:hover {
    box-shadow: none;
  }
  .div3 > p {
    white-space: wrap;

    margin-top: 0;
  }
  .erroralert {
    display: none;
  }
  .bottom-text {
    margin-top: 2vh;
  }
  .div4 {
    display: flex;
    width: 100%;
    height: 40vh;
    justify-content: center;
    align-items: center;
  }
  .top {
    font-size: 6vw;
  }

  .div3 {
    margin-top: -1vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .mobile-bottom-text {
    display: flex;
    text-align: center;
    font-size: 4vw;
    font-weight: 500;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-top: 3vh;
  }
}

